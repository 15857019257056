import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    compileKoPattern,
    parseBegSearchPattern,
    parseMidSearchPattern,
    parseEndSearchPattern,
    parseAdvancedSearchPattern,
} from './compileKoPattern';
import InfiniteScroll from 'react-infinite-scroller';

const MatchingWords = ({ q, mode }) => {
    const [matchingWords, setMatchingWords] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [error, setError] = useState(null);
    const modeToParseSearchPattern = {
        beg: parseBegSearchPattern,
        mid: parseMidSearchPattern,
        end: parseEndSearchPattern,
        advanced: parseAdvancedSearchPattern,
    };
    const parseSearchPattern =
        modeToParseSearchPattern[mode] || parseBegSearchPattern;

    const fetchMatchingWords = (page, reset = false) => {
        setError(null);
        const url = 'https://kosearch.hanmesoft.com/search_server/search.php';
        // The following is to fix the post params not being set as $_POST in php code.
        // Reference: https://github.com/axios/axios/issues/1195.
        const params = new URLSearchParams();
        params.append('q', parseSearchPattern(q));
        params.append('p', page);
        axios
            .post(url, params)
            .then((response) => {
                const responseData = response.data;
                setHasMore(responseData.hasMore);
                if (reset || matchingWords === null) {
                    setMatchingWords(responseData.wordInfos);
                } else {
                    setMatchingWords(
                        matchingWords.concat(responseData.wordInfos)
                    );
                }
            })
            .catch((error) => {
                setError(error);
            });
    };
    useEffect(() => {
        fetchMatchingWords(0, true);
    }, [q]);

    if (error) {
        return <div className="m-0 px-2 py-1">서버에 접속할 수 없습니다.</div>;
    }
    if (matchingWords === null) {
        return null;
    }
    if (matchingWords.length <= 0) {
        return <div className="m-0 px-2 py-1">검색 결과가 없습니다.</div>;
        return <div></div>;
    }
    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={fetchMatchingWords}
            hasMore={hasMore}
            loader={
                <div className="loader" key={0}>
                    Loading ...
                </div>
            }
        >
            <ul className="list-group list-group-flush list-group-hover">
                {matchingWords.map((elem, index) => (
                    <li className="list-group-item m-0 px-2 py-1" key={index}>
                        <h5 className="m-0 p-0 search-result-word">
                            <a
                                href={`https://ko.dict.naver.com/#/search?query=${elem.word}`}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                {elem.originalLanguage === ''
                                    ? elem.word
                                    : `${elem.word} (${elem.originalLanguage})`}
                            </a>
                        </h5>
                        <p className="m-0 p-0 ml-4">{elem.definition}</p>
                    </li>
                ))}
            </ul>
        </InfiniteScroll>
    );
};

export default MatchingWords;
