import { useState } from 'react';
import BottomBar from './BottomBar';
import MatchingWords from './MatchingWords';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Button, Tabs, Tab, Card } from 'react-bootstrap';
import ReactSnackBar from 'react-js-snackbar';
import { parseAdvancedSearchPattern } from './compileKoPattern';
import ReactGA from 'react-ga';

const App = () => {
    ReactGA.initialize('UA-185250602-2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    const [begInput, setBegInput] = useState('');
    const [midInput, setMidInput] = useState('');
    const [endInput, setEndInput] = useState('');
    const [advancedInput, setAdvancedInput] = useState('');
    const [snackbarShow, setSnackbarShow] = useState(false);
    const [snackbarShowing, setSnackbarShowing] = useState(false);

    const begHandleChange = (e) => {
        e.preventDefault();
        setBegInput(e.target.value);
    };
    const midHandleChange = (e) => {
        e.preventDefault();
        setMidInput(e.target.value);
    };
    const endHandleChange = (e) => {
        e.preventDefault();
        setEndInput(e.target.value);
    };
    const advancedHandleChange = (e) => {
        e.preventDefault();
        setAdvancedInput(e.target.value);
    };
    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        document.body.appendChild(textArea);
        textArea.value = text;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };
    const showSnackbar = () => {
        if (snackbarShowing) {
            return;
        }
        setSnackbarShowing(true);
        setSnackbarShow(true);
        setTimeout(() => {
            setSnackbarShow(false);
        }, 2000);
        setTimeout(() => {
            setSnackbarShowing(false);
        }, 3600);
    };
    const copyRegexpToClipboard = (e) => {
        copyToClipboard(parseAdvancedSearchPattern(advancedInput));
        showSnackbar();
    };

    return (
        <>
            <div className="container-fluid padding">
                <div className="row title text-center">
                    <div className="col-12 m-0 pb-0">
                        <h1 className="display-4 my-2">
                            <a
                                href="https://kosearch.hanmesoft.com"
                                className="text-decoration-none"
                            >
                                국어사전 자모검색
                            </a>
                        </h1>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                    <div className="col-sm-10 col-md-6 col-xl-4 m-0 py-0 px-3">
                        <Tabs>
                            <Tab eventKey="begSearch" title="초성검색">
                                <input
                                    type="text"
                                    className="form-control my-2"
                                    placeholder="초성검색    예) ㅅㅅ가ㅅ"
                                    onChange={begHandleChange}
                                    value={begInput}
                                />
                                <div className="text-left">
                                    <MatchingWords q={begInput} mode={'beg'} />
                                </div>
                            </Tab>
                            <Tab eventKey="midSearch" title="중성검색">
                                <input
                                    type="text"
                                    className="form-control my-2"
                                    placeholder="중성검색    예) ㅓㅏ가ㅏ"
                                    onChange={midHandleChange}
                                    value={midInput}
                                />
                                <div className="text-left">
                                    <MatchingWords q={midInput} mode={'mid'} />
                                </div>
                            </Tab>
                            <Tab eventKey="endSearch" title="종성검색">
                                <input
                                    type="text"
                                    className="form-control my-2"
                                    placeholder="종성검색    예) ㄹㅇxㅇ"
                                    onChange={endHandleChange}
                                    value={endInput}
                                />
                                <div className="text-left">
                                    <MatchingWords q={endInput} mode={'end'} />
                                </div>
                            </Tab>
                            <Tab eventKey="advancedSearch" title="고급검색">
                                <Card
                                    bg="light"
                                    className="text-left m-1 border-3"
                                >
                                    <Card.Header>
                                        <h5 className="m-0 p-0">
                                            고급검색 사용하는 법
                                        </h5>
                                    </Card.Header>
                                    <Card.Body className="p-2 pl-3">
                                        <p className="mb-3 p-0">
                                            초성, 중성, 종성 검색을 혼합하거나
                                            더 정교한 자모검색도 할 수 있습니다.
                                            글자하나를 표현할때
                                            &#123;초성패턴|중성패턴|종성패턴&#125;
                                            과 같이 하면 됩니다.
                                        </p>
                                        <p className="mb-3 p-0">
                                            예를 들어서:
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;초성이
                                            ㄱ인 글자: &#123;ㄱ&#125;
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;중성이
                                            ㅏ인 글자: &#123;|ㅏ&#125;
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;종성이
                                            ㅇ인 글자: &#123;||ㅇ&#125;
                                            <br />
                                        </p>
                                        <p className="mb-3 p-0">
                                            이 세 글자를 조합하여
                                            &#123;ㄱ&#125;&#123;|ㅏ&#125;&#123;||ㅇ&#125;
                                            와 같은 패턴으로 검색할 수 있습니다.
                                        </p>
                                        <p className="mb-0 p-0">
                                            더 정교한 패턴의 예:
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;초성이
                                            ㄱ또는 ㄴ이고 받침(종성)이 없는
                                            글자: &#123;ㄱㄴ||x&#125;
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;종성에
                                            ㄴ이 포함된 글자:
                                            &#123;||ㄴㄵㄶ&#125;
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;중성에
                                            ㅜ가 포함된 글자:
                                            &#123;|ㅜㅝㅞㅟ&#125;
                                            <br />
                                        </p>
                                    </Card.Body>
                                </Card>
                                <input
                                    type="text"
                                    className="form-control my-2"
                                    placeholder="고급검색    예) 설{ㅅ}{|ㅏ}{||ㅇ}"
                                    onChange={advancedHandleChange}
                                    value={advancedInput}
                                />
                                {advancedInput !== '' && (
                                    <div className="text-left m-2 p-0">
                                        <Button
                                            variant="info"
                                            onClick={copyRegexpToClipboard}
                                        >
                                            클립보드에 정규표현식 복사
                                        </Button>
                                    </div>
                                )}
                                <div className="text-left">
                                    <MatchingWords
                                        q={advancedInput}
                                        mode={'advanced'}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                        <div
                            style={{
                                height: '60px',
                            }}
                        ></div>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                </div>
            </div>
            <ReactSnackBar Icon={<span>📝</span>} Show={snackbarShow}>
                <div className="text-center">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;정규표현식이
                    복사되었습니다.
                </div>
            </ReactSnackBar>
            <BottomBar />
        </>
    );
};

export default App;
