import {
    getAllBitmap,
    strBitmapUnion,
    strBitmapCrosssection,
    extractLetters,
    rangeCompressLetters,
    getComponentList,
    getStrBitmapDic,
} from './koLetterBitmaps';

const parseComponentPattern = (ci, pattern) => {
    let b = null;
    for (let component of pattern.split('')) {
        const cb = getStrBitmapDic(ci, component);
        if (cb !== null) {
            if (b === null) {
                b = cb;
            } else {
                b = strBitmapUnion(b, cb);
            }
        }
    }
    if (b === null) {
        return getAllBitmap();
    } else {
        return b;
    }
};

const parseLetterPattern = (pattern) => {
    const componentPatterns = pattern.split('|');
    let b = null;
    for (let i = 0; i < componentPatterns.length; i++) {
        const componentPattern = componentPatterns[i];
        const cb = parseComponentPattern(i, componentPattern);
        if (b === null) {
            b = cb;
        } else {
            b = strBitmapCrosssection(b, cb);
        }
    }
    return b;
};

export const compileKoPattern = (regex) => {
    return regex.replace(/{(.*?)}/g, (match, contents) => {
        const b = parseLetterPattern(contents);
        const letters = extractLetters(b);
        const rangeCompressed = rangeCompressLetters(letters);
        return `[${rangeCompressed}]`;
    });
};

export const parseBegSearchPattern = (pattern) => {
    const regex = pattern.replace(
        /([ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎ])/g,
        (match, contents) => {
            return `{${contents}}`;
        }
    );
    return compileKoPattern(regex);
};

export const parseMidSearchPattern = (pattern) => {
    const regex = pattern.replace(
        /([ㅏㅐㅑㅒㅓㅔㅕㅖㅗㅘㅙㅚㅛㅜㅝㅞㅟㅠㅡㅢㅣ])/g,
        (match, contents) => {
            return `{|${contents}}`;
        }
    );
    return compileKoPattern(regex);
};

export const parseEndSearchPattern = (pattern) => {
    const regex = pattern.replace(
        /([xㄱㄲㄳㄴㄵㄶㄷㄹㄺㄻㄼㄽㄾㄿㅀㅁㅂㅄㅅㅆㅇㅈㅊㅋㅌㅍㅎ])/g,
        (match, contents) => {
            return `{||${contents}}`;
        }
    );
    return compileKoPattern(regex);
};

export const parseAdvancedSearchPattern = (pattern) => {
    return compileKoPattern(pattern);
};
