const BottomBar = () => {
    return (
        <div className="row fixed-bottom title text-center">
            <footer className="footer">
                <div className="footer-div">
                    <span className="vertical-align-middle">
                        &copy;{' '}
                        <a href="https://www.hanmesoft.com/">
                            Hanmesoft&nbsp;Corp.
                        </a>{' '}
                        All&nbsp;rights&nbsp;reserved. See&nbsp;
                        <a href="https://www.hanmesoft.com/provision.html">
                            Provision
                        </a>
                        &nbsp;and&nbsp;
                        <a href="https://www.hanmesoft.com/privacy.html">
                            Privacy Policy
                        </a>
                        .
                    </span>
                </div>
            </footer>
        </div>
    );
};

export default BottomBar;
